import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { Icon } from "semantic-ui-react";

import { Message } from "semantic-ui-react";

import { CSSTransition, TransitionGroup } from "react-transition-group"; // new
import LogoAnimation from "survey/SurveyComponents/LogoAnimations";

import {
  SG_ADD_EMPLOYEE_TO_DEBRIEF_SURVEY,
  SG_DEBRIEF_SURVEY,
  CLEAR_DEBRIEF_SURVEY,
} from "constants/actions";
import { get } from "http";

const SurveyStart = ({ title }) => {
  const dispatch = useDispatch();
  let { token } = useParams();

  const [debriefSurvey, setDebriefSurvey] = useState(false);
  const [direction, setDirection] = useState("slide");
  const [isVisible, setIsVisible] = useState(true);
  const [submitError, setSubmitError] = useState(false);

  const [email, setEmail] = useState("");

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);

  const [surveyCreated, setSurveyCreated] = useState(false);

  const { get_debrief_survey, get_errors } = useSelector(
    (state) => ({
      get_debrief_survey: state.debrief_survey,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  console.log("get_debrief_survey", get_debrief_survey);
  console.log("get_debrief_survey?.debrief_survey?.organization_info", get_debrief_survey?.debrief_survey?.organization_info);
  console.log("get_debrief_survey?.debrief_survey?.organization_name", get_debrief_survey?.debrief_survey?.organization_name);
  console.log("get_debrief_survey?.debrief_survey?.organization_services", get_debrief_survey?.debrief_survey?.organization_services);

  useEffect(() => {
    if (get_debrief_survey?.debrief_survey) {
      setDebriefSurvey(get_debrief_survey?.debrief_survey);
    }
  }, [get_debrief_survey.debrief_survey]);

  useEffect(() => {
    if (get_debrief_survey?.employee_added) {
        setSurveyCreated(get_debrief_survey?.employee_added);
        setFormErrors({submitted: {error: false, message: 'already submitted, reset the form'}, errors: 1});
      }
  }, [get_debrief_survey.employee_added]);


  useEffect(() => {
    if (token) {
      dispatch({
        type: SG_DEBRIEF_SURVEY,
        payload: token,
      });
    }
  }, [dispatch, token]);

  function handleSubmit() {
    if (formErrors?.errors > 0) {
      setPristine(false);
      return;
    }

    dispatch({
      type: SG_ADD_EMPLOYEE_TO_DEBRIEF_SURVEY,
      payload: {
        survey_id: token,
        email_id: email,
      },
    });
  }

  useEffect(() => {
    setSubmitError(get_errors?.errors?.data?.message?.message)
  },[get_errors])

  useEffect(() => {
    const ErrorList = {};
    let totalErrors = 0;
    setSubmitError(false);

    if (email?.length === 0) {
      ErrorList["email"] = {
        error: true,
        message: "Please enter an email",
      };
      totalErrors += 1;
    } else {
      if (!ValidateEmail(email)) {
        ErrorList["email"] = {
          error: true,
          message: "Please enter a valid email",
        };
        totalErrors += 1;
      }
    }
    if (!debriefSurvey?.survey_id) {
      ErrorList["debriefSurvey"] = {
        error: true,
        message: "No Valid Survey found",
      };
    }
    if (get_errors?.errors?.data?.detail) {
      ErrorList["token"] = {
        error: true,
        message: get_errors?.errors?.data?.detail,
      };
    }

    ErrorList["errors"] = totalErrors;
    setFormErrors(ErrorList);
  }, [email, pristine, debriefSurvey, get_errors]);

  function ChangeEmailId(e) {
    setEmail(e.target.value)
    setFormErrors({ email: { error: false, message: "" }, errors: 0 });
  }

  console.log("formErrors", get_errors?.errors?.data?.message?.message);
  console.log("get_errors", get_errors);

  return (
    <TransitionGroup>
      <CSSTransition
        key={token}
        classNames={direction}
        timeout={{ enter: 500, exit: 500 }}
        onEntered={() => setIsVisible(true)}
        onExited={() => setIsVisible(false)}
      >
        <ModuleContainer className="module-wrapper">
          <Form>
            {formErrors?.email?.error && !pristine && (
              <Message
                error
                // header={props.message}
                content={formErrors?.email?.message}
              />
            )}
            {formErrors?.token?.error && (
              <Message
                error
                // header={props.message}
                content={formErrors?.token?.message}
              />
            )}
            {submitError && (
              <Message
                error
                // header={props.message}
                content={submitError}
              />
            )}
            {surveyCreated?.message?.message && (
              <Message
                success
                // header={props.message}
                content={surveyCreated?.message?.message}
              />
            )}
            <Title>{title}</Title>
            <Input
              type="text"
              value={email}
              onChange={(e) => ChangeEmailId(e)}
              placeholder="Enter email "
            />
            <Button type="submit" onClick={handleSubmit}>
              Submit
            </Button>
          </Form>
        </ModuleContainer>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default SurveyStart;

export const ValidateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
const ModuleContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-height: 100vh;
  overflow: hidden;
  z-index: 3;

  @media (max-height: 875px) and (max-width: 768px) {
    align-items: flex-start;
    top: 125px;
    max-height: calc(100vh - 125px);
  }

  @media (max-height: 750px) and (max-width: 768px) {
    align-items: flex-start;
    top: ${(props) => (props.selected == "categories" ? "75px" : "125px")};
    max-height: calc(100vh - 125px);
  }

  @media (max-height: 550px) {
    align-items: flex-start;
    top: 75px;
    max-height: calc(100vh - 75px);
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 1.4;
  color: #2a3039;
  margin-bottom: 16px;
`;

const Input = styled.input`
  font-size: 16px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
  margin-bottom: 16px;
`;

const Button = styled.button`
  background-color: #2d70e2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #1b5fc4;
  }
`;
