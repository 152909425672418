

export const structurePrompt = (responses) => {
    let follow_up = false
    let prompt = 'The following are the quantitative responses to a culture survey from a respondent:\n\n'
    Object.keys(responses).map((i) => {
        if(responses[i].type == "quantitative"){
            prompt += `Question: ${responses[i].q.question}\Score: ${responses[i].response} /10\n\n`
        }
        if(responses[i].type == 'ai'){
            follow_up = true
            prompt +='\n\n Based on the quantitative questions, we have already generated the following follow up question(s), and included are the respondents answer(s):\n'
            prompt += `Question: ${responses[i].question}\nResponse: ${responses[i].response}\n\n`
        }
        
    })

    if(follow_up){

        prompt += `## TASK:\nYour goal is to generate a follow up question based on the respondents last response that is gets feedback that is actionable and can be used to generate recommendations for the organization. 
        Dont be ambiguous about the question. You should focus on understanding why the respondent gave the response they did, and provide enough information for the 
        respondent to understand what you are asking. If the scores are predominantly positive, then you should seek to understand what is working well and why.
        You are trying gain a better understanding of the respondents perspective and potential solution. The goal here is to understand to the greatest extent possible the cause and potential solutions to the lowest
        scoring areas. Deeper context and understanding about a single very low score is always better then shallow understanding of multiple scores. \n\n
        The question should be simple enough for a 7th grader to understand. Only ever return the question itself. Never reference a question number. Be compassionate and empathetic in your question.
        Act as a coach, not a judge. \n\n
        Question:\n`

    }else{
        prompt += `## TASK:\nYour goal is to generate a follow up question which is designed to get feedback which will
        get the respondent to elaborate on their score in such a way that the feedback is actionable and can be used to generate recommendations for the organization. 
        Dont be ambiguous about the score or question. You should focus on understanding why the respondent gave the score they did, and provide enough information for the 
        respondent to understand what you are asking. If the scores are predominantly positive, then you should seek to understand what is working well and why.
        The question should be simple enough for a 7th grader to understand. Only ever return the question itself. Never reference a question number. Be compassionate and empathetic in your question.
        Act as a coach, not a judge. \n\n
        Question:\n`

    }

    return prompt


}