import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_DEBRIEF_SURVEY } from "constants/actions";
import { useNavigate } from "react-router-dom";

const Screen1 = ({ title }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [text, setText] = useState("");
  const [token, setToken] = useState(false);

  const { get_debrief_survey, get_errors } = useSelector(
    (state) => ({
      get_debrief_survey: state.debrief_survey,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_debrief_survey?.debrief_survey?.survey_id) {
      setToken(get_debrief_survey?.debrief_survey?.survey_id);
      navigate(`/culture-audit/start/${get_debrief_survey?.debrief_survey?.survey_id}`);
    }
  }, [get_debrief_survey.debrief_survey, navigate]);

//   {
//     "id": 848,
//     "survey_id": "336dab17-a2a8-4dbc-b036-96a4c563e071",
//     "name": "San Fransisco",
//     "name_uid": "c05b3038-73b5-48dc-af52-5af2c499bdc5",
//     "status": 3,
//     "status_text": "Live",
//     "close_at": "2024-09-03T13:40:10.384000Z"
// }

  console.log("get_debrief_survey", get_debrief_survey?.debrief_survey);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(text);
    if (text) {
      dispatch({
        type: SG_DEBRIEF_SURVEY,
        payload: text,
      });
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Title>{title}</Title>
        <Input
          type="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Enter token here"
        />
        <Button type="submit">Submit</Button>
      </Form>
    </Container>
  );
};

export default Screen1;

// Styled components
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 1.4;
  color: #2a3039;
  margin-bottom: 16px;
`;

const Input = styled.input`
  font-size: 16px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
  margin-bottom: 16px;
`;

const Button = styled.button`
  background-color: #2d70e2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #1b5fc4;
  }
`;
