// ... other imports ...

import styled from 'styled-components';
import React,{ useState } from 'react';
import Screen1 from './IntroScreens/screen1'
import Screen2 from './IntroScreens/screen2'
import Screen4 from './IntroScreens/screen4'
import Screen3 from './IntroScreens/screen3'
import Screen5 from './IntroScreens/screen5'

const Introduction = ({id,title,language,disableNext,ai_disclaimer}) => {

  const renderIntro = (screen_id) => {
    switch (screen_id) {
      case 1:
        return <Screen1 title={title} language={language}/>;
      case 2:
        return <Screen2 language={language}/>;
      case 3:
        return <Screen3 language={language}/>;
      case 4:
          return <Screen4 language={language} ai_disclaimer={ai_disclaimer}/>;
      case 5:
          return <Screen5 language={language} disableNext={disableNext}/>;
      default:
        return <Screen1/>;
    }
  }

  return (
    <IntroductionContainer>
      {renderIntro(id)}
     </IntroductionContainer>
  );
};


export default Introduction;

const IntroductionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width:600px;
`;

const IntroductionText = styled.p`
  font-size: 1.25rem;
  text-align: center;
`;