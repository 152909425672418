import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { Routes, Route } from 'react-router-dom';

import { CSSTransition, TransitionGroup } from "react-transition-group"; // new

import Survey from "./Survey";
import Default from "./Default";

const SurveyStartHome = () => {
  const dispatch = useDispatch();
  let { token } = useParams();

  const [direction, setDirection] = useState("slide");
  const [isVisible, setIsVisible] = useState(true);

  console.log("token", token);
  return (
    <TransitionGroup>
      <CSSTransition
        key={token}
        classNames={direction}
        timeout={{ enter: 500, exit: 500 }}
        onEntered={() => setIsVisible(true)}
        onExited={() => setIsVisible(false)}
      >
        <Routes>
          <Route path="/" element={<Default title={"Enter Token"} />} />
          <Route path="/:token" element={<Survey title={"Enter Email"} />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default SurveyStartHome;

const ModuleContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-height: 100vh;
  overflow: hidden;
  z-index: 3;

  @media (max-height: 875px) and (max-width: 768px) {
    align-items: flex-start;
    top: 125px;
    max-height: calc(100vh - 125px);
  }

  @media (max-height: 750px) and (max-width: 768px) {
    align-items: flex-start;
    top: ${(props) => (props.selected == "categories" ? "75px" : "125px")};
    max-height: calc(100vh - 125px);
  }

  @media (max-height: 550px) {
    align-items: flex-start;
    top: 75px;
    max-height: calc(100vh - 75px);
  }
`;
