import React from 'react';
import styled, { keyframes } from 'styled-components';
// import Arrow from 'assets/images/bi_arrow-right-square-fill.svg'
import {ArrowIcon} from './ArrowIcon'

const Intro = ({ saveAnswer, textColor,
  background, language, organization, primary, surveyName,text,organizationInfo }) => {

    const getOrgName = () => {
      if (organizationInfo.EN_ORG && organizationInfo.FR_ORG) {
        if(language === 1){
          return organizationInfo.FR_ORG
        }
        else{
          return organizationInfo.EN_ORG
        }
      }
  
      if(language===1){
       return  `${surveyName} - ${organization}`
      }
  
      return `${organizationInfo?.organization != "1" ? surveyName+' - ':""}${organization}`
  
  }
  
  
    const [page] = React.useState(0)
    const FR1 = `Merci d'avoir répondu à ces questions préliminaires. Nous allons maintenant vous poser quelques questions sur votre expérience avec ${getOrgName()}. Votre expérience compte.`
    const EN1 = `Thank you for answering these preliminary questions. Now we are going to ask you some questions about your experience with ${getOrgName()}. Your experience matters.`
  
  function StartSurvey() {
    saveAnswer()
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [page])

  console.log('here')

  return (
    <Container color={background}>
          <Content color={textColor}>
            <SubTitle>{text?text:language==1?FR1:EN1}</SubTitle>
            <Next enabled={true}
              onClick={StartSurvey}
              id="begin"
              color={textColor}>
              <Image>
                {ArrowIcon(textColor)}
              </Image>
            </Next>
          </Content>
    </Container>
  )
}

export default Intro

const SubTitle = styled.div`
	font-size: 18px;
	line-height: 1.5;
  color: white;
  margin-bottom:30px;
  max-width:654px;
  width:100%;


   @media only screen and (min-width:992px){
   font-size: 72px;
   margin-bottom:20px;
   font-size: 20px;
  }


`

const Next = styled.div`
  cursor:pointer;
  border: none;
  font-family: 'Poppins', sans-serif;
  width:100%;
  display:flex;
  justify-content:flex-end;
 
`

const Container = styled.div`
  position:absolute;
  top:10%;
  padding:20px;
  background:${props => props.color};


  @media only screen and (min-width:768px){
    left: calc(50% - 350px);
  top: 15%;
  }

  @media only screen and (max-height:550px){
  top: 10%;
  }

   @media(max-width:350px){
    height:850px;
  }
`

const first = keyframes`
  from {
    opacity:0;
  }

  to {
    opacity:1;
  }
`;

const Content = styled.div`
  animation: ${first} 0.5s linear;

  a{
    color:${props => props.color === '#15056b' ? 'white' : props.color};
    text-decoration:underline;
  }
`

const Image = styled.div`
  height:50px;
  cursor:pointer;
`