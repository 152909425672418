export const productionApi = 'https://api.innerlogic.com'
// export const staginApi = 'https://stg-api.innerlogic.ca'
export const stagingApi = 'https://django-api-staging.innerlogic.ca'

export const localApi = 'http://127.0.0.1:8000'

// Publishable key
export const StripeKey = 'pk_test_bowWtykmtAwZxvD0bvdXEycG00LczLxeDB'
export const currentApi = stagingApi 

export const Environment = 'Development'

export const SentryDSN_DEV = "" 
export const SentryDSN_PROD = "https://b353fc5849e94e14be0255bb24eff1fb@o1167548.ingest.sentry.io/6258731"
export const SentryDSN_STG = "https://77a3a75262414b558301b2aeb57c7b3b@o1167548.ingest.sentry.io/6258797"

export const config = {
    loginapi: `${currentApi}/api/account`,
    authapi: `${currentApi}/api/rest-auth`,
    surveyapi: `${currentApi}/api/survey`,
    appapi: `${currentApi}/api/organization`,
    website: `${currentApi}/api/web`,
};
export default config
