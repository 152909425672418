import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import Slider from './Slider'


const OutcomeQuestion = ({
  currentQuestion,
  saveAnswer,
}) => {

  // const [respValue, setRespValue] = useState(0)


  // useEffect(() => {
  //   setRespValue('')
  //   if (currentQuestion?.response) {
  //     setRespValue(currentQuestion?.response)
  //   }
  // }, [currentQuestion]);

  const save = (resp) => {
    const _answer = {...currentQuestion}
    _answer['response'] = resp

    saveAnswer(_answer)
    // setRespValue('')
    // 
}
  // console.log(currentQuestion)
  return (
    <>
      <SwitchTransition>
        <CSSTransition
          key={0}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="feedback"
        >
          <SurveyContainer>


            <Container>

              <Text>{currentQuestion?.question}</Text>
              <Slider
                preValue={currentQuestion?.response || 0}
                scale={currentQuestion?.scale}
                id={currentQuestion?.id}
                // setCheckResponse
                //   color={textColor}
                //   language={language}
                saveResult={(resp) => save(resp)} />
            </Container>
          </SurveyContainer>


        </CSSTransition>
      </SwitchTransition>
      <SurveyContainer>

      </SurveyContainer>

    </>
  )
}

export default OutcomeQuestion


const SurveyContainer = styled.div`
  padding: 0px;
  box-sizing: border-box;
  position: absolute;
  left: 0%;
  top: 10%;
  padding: 20px;

  @media only screen and (min-width: 768px) {
    padding: 30px;
    left: calc(50% - 350px);
    top: 15%;
  }
`;

const Container = styled.div`
  padding:30px;
  position:relative;
  width:100%;
`
const Text = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: white;
  margin-top: 14px;
  max-width:600px;
  line-height:1.3;
  margin-bottom:30px;

  @media only screen and (min-width: 992px) {
    font-size: 23px;
    margin-top: 28px;
  }
`;

const SubText = styled.textarea`
  width:600px;
  color: black;
  font-size: 16px;
  background-color:white;
  border-radius:8px;
  border:none;
  padding:15px;
  resize: none;

  &:focus{
  outline: none;
  }

  ::placeholder{
    color: #b8b8b8;
  }

  @media(max-width:768px){
    max-width:calc(100vw - 40px);

  }
`

const Button = styled.button`
  height: 40px;
  border-radius: 10px;
  background-color: white;
  border: 0;
  box-shadow: none;
  color: ${props => props.color};
  font-size: 16px;
  font-weight: bold;

  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100px;
  margin-top: 50px;
  // display: none;

  :disabled{
  	background-color:lightgrey;
  	color:grey;
  	cursor:not-allowed;
  	opacity:0.5;
  }


  @media only screen and (min-width: 992px), (min-height: 760px) {
    display: flex;
  }
`;