import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import format from "date-fns/format";
import { words } from '../translations';
const FR1 = "Nous ne pouvons pas obtenir d'informations sur cette enquête"
const FR2 = "Cette enquête a été complétée"
const FR3 = "Cette enquête a été fermée et n'est plus accessible"
const FR4 = "Hmm! Il semble que cette enquête a expiré"

const ES1 = "No podemos obtener información sobre esta encuesta"
const ES2 = "Esta encuesta ha sido completada"
const ES3 = "Esta encuesta ha sido cerrada y ya no es accesible"
const ES4 = "Hmm! Parece que esta encuesta ha expirado"


const Completed = ({ status, language, statusText, completedOn, surveyStatus,title }) => {
  const [endDate, setEndDate] = useState(false)

  let CustomMessage = statusText ? statusText : words["survey_status1"][language]

  useEffect(() => {
    if (completedOn) {
      setEndDate(format(new Date(completedOn), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"))
    }
  }, [completedOn])

  if(language == 'FR'){
    CustomMessage = FR1
  }

  if(language == 'ES'){
    CustomMessage = ES1
  }

  if (language === 'FR' && status === 2) {
    CustomMessage = FR2
  }

  if (language== 'FR' && status === 3) {
    CustomMessage = 'This survey has been completed'
  }

  if (language === 'ES' && status === 2) {
    CustomMessage = ES2
  }

  if (language == 'ES' && status === 3) {
    CustomMessage = 'Esta encuesta ha sido completada'
  }



  if (status > 4 && status < 11) {
    CustomMessage = 'This survey has been closed and is no longer accessible'
    if (language === 'FR') {
      CustomMessage = FR3
    }
    if (language === 'ES') {
      CustomMessage = ES3
    }
  }
  if (status === 100) {
    CustomMessage = 'Hmm! It looks like this survey has expired'
    if (language === 'FR') {
      CustomMessage = FR4
    }
    if (language === 'ES') {
      CustomMessage = ES4
    }
  }


  const getTitle = () =>{
    if(language === 'FR'){
      return 'Indice de Culture'
    }
    if(language == 'ES'){
      return 'Índice de Cultura'
    }
    return title? title:'Culture Index'
  }

console.log(language)
  return (
    <FeedbackContainer>
      <Emoji> <span role="img" aria-label="completed">🎉</span> </Emoji>
      <QuestionText>{getTitle()}</QuestionText>
      <Description>
        {statusText ? statusText : CustomMessage}
        {endDate ? ` ${format(
          new Date(endDate.toLocaleString()),
          "EEEE, MMM do h:mm a"
        )}.` : '.'}


      </Description>
    </FeedbackContainer>
  )
}


export default Completed;


const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width:100%;
  max-width:600px;
  font-family: 'Raleway', sans-serif;
`;

const QuestionText = styled.h2`
  margin-bottom: 1rem;
  color:#2A3039;
  font-size: 20px;
  font-weight: 600;
`;

const Description = styled.div`
color: #2A3039;
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 16px;
margin-bottom:26px;
line-height: 150%;
`
const Emoji = styled.div`
    font-size: 48px;
    margin-bottom:24px;
`
